import { StrictMode } from "react";
import { render } from "react-dom";
import App from "./components/App";
import { loadConfig } from "./config";


const run = async () => {
  try {
    await loadConfig();
  } catch (e: any) {
    console.error(`Error loading configuration ${e.message}.`);

    if (process.env.NODE_ENV === "production") {
      return;
    }

    console.info(`Falling back to default config.`);
  }

  render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById("root")
  );
};

run();
