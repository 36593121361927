import { IPFSService, IpfsData, IpfsDataVersion } from "@vereign/light-utils";
import getConfig from "../config";
import { saveAs } from "file-saver";

export const getDecodedContent = async (
  cid: string,
  decryptionKey: string,
  head: string,
  ipfsVersion: IpfsDataVersion
): Promise<ArrayBuffer> => {
  const config = getConfig();
  const ipfsService = new IPFSService(config.IPFS_API_URL);
  const ipfsContent = await ipfsService.getDecodedContent(
    {
      cid,
      key: decryptionKey,
      head: head,
    },
    ipfsVersion
  );
  return ipfsContent;
};

export const downloadAttachment = async (
  ipfs: IpfsData,
  contentHash: string,
  fileName: string
) => {
  const attachmentData = ipfs.attachments.find(
    (a) => a.contentHash === contentHash
  );
  if (!attachmentData) return;

  const file = await getDecodedContent(
    attachmentData.cid,
    attachmentData.key,
    attachmentData.head,
    ipfs.version
  );

  const blob = new Blob([file]);
  saveAs(blob, fileName);
};


export const getAttachment = async (
  ipfs: IpfsData,
  contentHash: string,
) => {
  const attachmentData = ipfs.attachments.find(
    (a) => a.contentHash === contentHash
  );
  if (!attachmentData) return;

  const file = await getDecodedContent(
    attachmentData.cid,
    attachmentData.key,
    attachmentData.head,
    ipfs.version
  );

  const blob = new Blob([file]);
  return blob
};
