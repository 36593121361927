


export const sumOfArray = ({
  arr, 
  initialValue,
  arrayObjectKey
}: {arr: any[]; initialValue: number, arrayObjectKey?: string}) => {

  let sum: number;
  if (arrayObjectKey) {
    sum = arr.reduce(
      (accumulator, currentValue: any) => accumulator + currentValue[arrayObjectKey],
      initialValue
    );

    return sum
  }

  sum = arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );

  return sum
}