import { md5 } from "./md5";
import { CryptoService } from "@vereign/light-utils"

export const formatBytesSize = (bytes: number): string => {
  if (isNaN(bytes) || bytes <= 0 || bytes === Infinity) {
    return "0";
  }

  const degrees = ["b", "kb", "mb", "gb"];
  let degree = 0;

  let value = bytes;
  while (true) {
    if (Math.round(value / 1024) === 0) {
      return `${Math.round(value * 100) / 100}${degrees[degree]}`;
    }

    value = value / 1024;
    degree++;
  }
};

export function base64ToHex(str: string) {
  const raw = atob(str);
  let result = "";
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : "0" + hex;
  }
  return result.toLowerCase();
}

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document

  const selection = document.getSelection();
  const selected =
    selection && selection.rangeCount > 0 // Check if there is any content selected previously
      ? selection.getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selection && selected) {
    // If a selection existed before copying
    selection.removeAllRanges(); // Unselect everything on the HTML document
    selection.addRange(selected); // Restore the original selection
  }
};

export const truncateString = (str: string, length: number): string => {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }

  return str;
};

//TODO: once fixed lib-seal / seal libraries move this there.
export const getSealId = async (sealUrl: string): Promise<string> => {
  const sealHash = await CryptoService.SHA256(sealUrl);
  return Buffer.from(sealHash).toString("hex");
};

const decodeBase64URL = (input: string): string => {
  return input.replace(/_/g, "/").replace(/-/g, "+");
};

//TODO: once fixed lib-seal / seal libraries move this there.
export const getSealHead = (sealUrl: string): string => {
  const url = new URL(sealUrl);
  return decodeBase64URL(url.hash.replace("#", ""));
};

export const generateColorFromString = (
  str: string,
  saturation = 100,
  lightness = 75
) => {
  let hash = 0;
  let salt = 123081353;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash+salt;
  }

  if (str === '') {
    return `hsl(25, 0%, 0%)`;
  }

  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export const requestGravatar = (email: string | undefined) => {
  if (!email) {
    return;
  }

  let hash = md5(email.toLowerCase().trim());

  let url = `https://www.gravatar.com/avatar/${hash}?s=200&d=404`;

  return url;
};
