
import classnames from "classnames";

import "./CardWithIcon.scss";
import Card, { Props as CardProps } from "../Card";

export type IconBgType = "white" | "red" | "pastelGreen" | "mauvelous";

export interface Props extends CardProps {
  icon: any;
  iconBg: IconBgType;
}

const ICON_BG_COLORS = {
  red: "cardWithIcon__circle--red",
  white: "cardWithIcon__circle--white",
  mauvelous: "cardWithIcon__circle--mauvelous",
  pastelGreen: "cardWithIcon__circle--pastel-green",
};

const CardWithIcon = ({
  children,
  icon,
  iconBg,
  className,
  ...props
}: Props) => {
  const circleClass = ICON_BG_COLORS[iconBg];
  return (
    <Card className={classnames("cardWithIcon", className)} {...props}>
      <span className={classnames("cardWithIcon__circle", circleClass)}>
        {icon}
      </span>
      {children}
    </Card>
  );
};

export default CardWithIcon;
