import { useState, useCallback } from "react";
import { IpfsData } from "@vereign/light-utils";
import * as ipfsBE from "../services/IPFS";
import { saveAs } from "file-saver";
import JSZip from 'jszip';

interface LoadingHashed {
  [key: string]: boolean;
}

export default (ipfs?: IpfsData) => {
  const [loadingHashes, setLoadingHashes] = useState<LoadingHashed>({});

  const downloadAttachment = useCallback(
    async (contentHash: string, fileName: string) => {
      if (!ipfs) return;

      if (loadingHashes[contentHash]) return;

      setLoadingHashes({
        ...loadingHashes,
        [contentHash]: true,
      });
      try {
        await ipfsBE.downloadAttachment(ipfs, contentHash, fileName);
      } catch (e) {}

      delete loadingHashes[contentHash];
      setLoadingHashes({ ...loadingHashes });
    },
    [ipfs, loadingHashes]
  );

  const downloadAllAttachments = useCallback(
    async (content: {hash: string, name: string}[]) => {
      if (!ipfs) return;

      const zip = new JSZip();

      const img = zip.folder("ipfsFiles");

      // if (loadingHashes[contentHash]) return;

      // setLoadingHashes({
      //   ...loadingHashes,
      //   [contentHash]: true,
      // });
      let fileArray = [];
      let generatedZipName = 'seal_download_'
      try {
        for await (let item of content) {
          let contentBlob = ipfsBE.getAttachment(ipfs, item.hash);
          generatedZipName += item.hash.substring(0,4);
          fileArray.push({contentBlob: await contentBlob, name: item.name})
        }
      } catch (e) {}

      if (fileArray.length > 0 && img) {
        
        for await (let zipItem of fileArray) {
          img.file(zipItem.name, zipItem.contentBlob as any);
        }

        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, `${generatedZipName}.zip`);
        });
      }

      // delete loadingHashes[contentHash];
      // setLoadingHashes({ ...loadingHashes });
    },
    [ipfs]
  );

  return {
    loadingHashes,
    downloadAttachment,
    downloadAllAttachments
  };
};
