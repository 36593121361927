import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";

import "./ProgressCard.scss";
import {
  STATE_ERROR,
  STATE_LOADING_STATUS_DATA,
  STATE_STATUS_DATA_PENDING,
  STATE_TAIL_PENDING,
  STATE_UNVERIFIED,
} from "../../../../hooks/useVerificationFlow";

import Spinner from "../../../../components/Spinner";
import useWindowSize, {
  BREAKPOINT_DESKTOP,
} from "../../../../hooks/useWindowSize";
import Card, { ColorsType } from "../../../../components/Card";
import GeneratedAvatar from "../../../../components/GeneratedAvatar/GeneratedAvatar";
import { MessageData, Participant } from "@vereign/light-utils";
import classNames from "classnames";
import genericPerson from '../../../../assets/images/generic-person.svg'

interface Props {
  verificationState: number;
  sealTime: number;
  qrCodeData?: MessageData;
}

const DATE_FORMAT_DESKTOP = "dd\xa0MMM\xa0yyyy, HH:mm O";
const DATE_FORMAT_MOBILE = "dd/MM/yy HH:mm:ss";

const ProgressCard = ({ verificationState, sealTime, qrCodeData }: Props) => {
  const { width } = useWindowSize();

  const loading = verificationState === STATE_LOADING_STATUS_DATA

  const [ expandedRecipients, setExpandedRecipients ] = useState(false);

  let cardColor: ColorsType = "transparent";
  const cardLabel = (() => {
    switch (true) {
      case verificationState === STATE_ERROR:
        return "Error";
      case verificationState === STATE_UNVERIFIED:
        return "Unverified";
      case verificationState === STATE_STATUS_DATA_PENDING ||
        verificationState === STATE_TAIL_PENDING:
        return "Pending";
      case !!sealTime:
        cardColor = "transparent";
        return (
          <span className="progress-card__label__time">
            {format(
              sealTime,
              isMobile ? DATE_FORMAT_MOBILE : DATE_FORMAT_DESKTOP
            )}
          </span>
        );
      default:
        return "Working";
    }
  })();

  let iconSize = width > BREAKPOINT_DESKTOP ? 100 : 50; // default size

  let stateIcon;


  if (qrCodeData) {
    stateIcon = <GeneratedAvatar email={qrCodeData?.sender.email} personName={qrCodeData?.sender.name} />;
  } else {
    stateIcon = <Spinner height={iconSize} width={iconSize} />;
  }

  if (verificationState === STATE_ERROR || verificationState === STATE_UNVERIFIED) {
    stateIcon = <img 
      src={genericPerson}
      width={100}
      className="progress-card__avatar"
      style={{background: '#f4f6f7', borderRadius: '50%'}}
      alt='generic person'
    />
  }


  const renderRecipients = (recipients?: Array<Participant>, isCC: boolean = false) => {

    let recipientListCap = 3;

    if (expandedRecipients && recipients) {
      recipientListCap = recipients.length
    }
    

    if (Array.isArray(recipients)) {
      return recipients.map((recipient, i) => (
        i <= recipientListCap-1 ? 
          <li 
            className={(expandedRecipients || width < BREAKPOINT_DESKTOP) ? "message-details-card__recipients-list-item" : "message-details-card__recipients-list-item--compact"} 
            key={i+'recipient'+recipient.name}
            style={{
              zIndex: recipients && recipients.length - i,
            }}
          >
            {recipient.email && (
              <div className="message-details__recipient-box">
                <div className="message-details__recipient-avatar">
                  {!loading ?
                    <GeneratedAvatar email={recipient.email} personName={recipient.name} smallFont={true}/>
                    :
                    <Spinner height={40} width={40} />
                  }
                </div>
                {(expandedRecipients || width < BREAKPOINT_DESKTOP) ? 
                  <span className="message-details__recipient-email">
                    {isCC &&
                      <span className="message-details__recipient-email">
                        CC:
                      </span>
                    }
                    {recipient.email && `(${recipient.email})`}
                  </span> : <></>
                }
              </div>
            )}
          </li>
        :
        i === recipientListCap && 
          <li 
            className="message-details-card__recipients-list-item" 
            key={i+'more'}
            style={{
              margin: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            + {recipients.length - recipientListCap} more
          </li> 
        
      ));

    }
  };

  const recipients = useMemo(() => {
    let result1: Participant[] = [];
    let result2: Participant[] = [];

    if (qrCodeData?.recipients?.to) {
      result1 = [...qrCodeData.recipients.to];
    }

    if (qrCodeData?.recipients?.cc) {
      result2 = [...qrCodeData.recipients.cc];
    }

    return {
      recipients: result1,
      cc: result2
    };

  }, [qrCodeData]);


  useEffect(() => {
    setExpandedRecipients(true)
    if (recipients.recipients.length + recipients.cc.length > 3) {
      setExpandedRecipients(false)
    }
  }, [recipients])
  

  return (
    <section className="progress-card">
      <Card color={cardColor} className="progress-card__content">
        <div className="progress-card__avatar">{stateIcon}</div>
        <div className="progress-card__label">
          <p>{qrCodeData ? 'Sealed by:' : ''}</p>
          <p>{qrCodeData?.sender.name}</p>
          <p>{qrCodeData?.sender.email}</p>
        </div>
        <span className="message-details-card__recipients-title">Recipients:</span>
        {!loading ?
          <ul
            className={classNames((expandedRecipients || width < BREAKPOINT_DESKTOP) ? "message-details-card__recipients-list message-details-card__recipients-list--expanded" :"message-details-card__recipients-list")}
            onMouseEnter={(e) => recipients.recipients.length + recipients.cc.length > 3 && setExpandedRecipients(true)}
            onMouseLeave={(e) => recipients.recipients.length + recipients.cc.length > 3 && setExpandedRecipients(false)}
          >
            {renderRecipients(recipients.recipients)}
            {recipients.cc && recipients.cc.length > 0 &&
              <div
                style={{
                  marginLeft: (expandedRecipients || width < BREAKPOINT_DESKTOP) ? 0 : '10px',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                {!expandedRecipients && width > BREAKPOINT_DESKTOP && 'CC:'} 
                {renderRecipients(recipients.cc, true)}
              </div>
            }
            
          </ul>
          :
          <Spinner height={40} width={40}/>
        }
        <div className="progress-card__sublabel">
          <p>{cardLabel}</p>
        </div>
        
      </Card>
    </section>
  );
};

export default ProgressCard;
