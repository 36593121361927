
import { useEffect, useState } from "react";

import "../BlockchainInfo/BlockchainInfo.scss";

import Card from "../../../../components/Card";
import { MessageData } from "@vereign/light-utils";
import { copyToClipboard } from "../../../../utils/stringUtils";
import pinIcon from "../../../../assets/images/hash.png";

// Ignore TS errors until we have type definition for react-ui
// @ts-ignore
import { Tooltip } from "@vereign/react-ui";



interface Props {
  qrCodeData?: MessageData;
}

interface CIDData {
  htmlCID: string | undefined;
  plainTextCID: string | undefined;
  attachmentsCIDs: string[] | undefined;
  attachmentsNames: string[] | undefined;
}

const IPFSInfo = ({ qrCodeData }: Props) => {
  const [cidData, setcidData] = useState<CIDData>();

  useEffect(() => {
    let attachmentsCidArray: string[] | undefined = [];
    attachmentsCidArray = qrCodeData?.ipfs?.attachments.map((a) => {
      return a.cid;
    });

    let attachmentsNameArray: string[] | undefined = [];
    attachmentsNameArray = qrCodeData?.attachments.map((a) => {
      return a.name;
    });

    setcidData({
      htmlCID: qrCodeData?.ipfs?.html.cid,
      plainTextCID: qrCodeData?.ipfs?.plaintText.cid,
      attachmentsCIDs: attachmentsCidArray,
      attachmentsNames: attachmentsNameArray,
    });
  }, [qrCodeData]);

  function listAttachmentCIDs(
    cidArr: string[] | undefined,
    nameArr: string[] | undefined
  ) {
    if (
      !cidArr ||
      (cidArr && cidArr.length < 1) ||
      !nameArr ||
      (nameArr && nameArr.length < 1)
    ) {
      return [];
    }

    let elementArray = [];

    for (let i = 0; i < cidArr?.length; i++) {
      cidArr[i] && elementArray.push(
        <li key={i} className="blockchain-info__list-item">
          <Tooltip text="Copy CID">
            <img className="blockchain-info__copyIcon" onClick={() => copyToClipboard(cidArr[i] as string)} src={pinIcon} alt='pin icon'/>
          </Tooltip>
          <span className="blockchain-info__list-item-value">
            {cidArr[i] || "Unknown"}
          </span>
        </li>
      );
    }

    return elementArray;
  }

  return (
    <Card color="white" className="blockchain-info__container--right">
      <h1 className="blockchain-info__title">IPFS CIDs for this record</h1>
      <ul className="blockchain-info__list">
        {cidData?.htmlCID &&
        <li className="blockchain-info__list-item">
          <Tooltip text="Copy CID">
            <img className="blockchain-info__copyIcon" onClick={() => copyToClipboard(cidData?.htmlCID as string)} src={pinIcon} alt='pin icon'/>
          </Tooltip>
          <span className="blockchain-info__list-item-value">
            {cidData?.htmlCID || "N/A"}
          </span>
        </li>
        }

        {cidData?.plainTextCID &&
        <li className="blockchain-info__list-item">
          <Tooltip text="Copy CID">
            <img className="blockchain-info__copyIcon" onClick={() => copyToClipboard(cidData?.plainTextCID as string)} src={pinIcon} alt='pin icon'/>
          </Tooltip>
          <span className="blockchain-info__list-item-value">
            {cidData?.plainTextCID || "N/A"}
          </span>
        </li>
        }

        {listAttachmentCIDs(
          cidData?.attachmentsCIDs,
          cidData?.attachmentsNames
        )?.map((el) => {
          return el;
        })}
      </ul>
    </Card>
  );
};

export default IPFSInfo;
