import axios from "axios";
import getConfig from "../config";

export const unlockAESKey = async (
  keySignature: string,
  encryptedKey: string
): Promise<string> => {
  const response = await axios({
    url: `${getConfig().QRCODE_HSM_API_URL}/hsm/decrypt`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      key_signature: keySignature,
      encrypted_key: encryptedKey,
    },
  });

  return (response.data as any).key;
};

export const getTotalSealedCount = async (): Promise<number> => {
  const response = await axios({
    url: `${getConfig().BLOCKCHAIN_CACHE_SERVICE_URL}/getTotalCount`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (response.data as any).data;
};
