import { useState, useEffect } from "react";
import {
  arrayBufferToBase64,
  IpfsAttachmentData,
  IpfsData,
} from "@vereign/light-utils";
import { getDecodedContent } from "../services/IPFS";

const CID_REGEX = /"cid:([\d\w-]+)"/g;

export default (ipfs?: IpfsData) => {
  const [html, setHtml] = useState<string>("");
  const [plainText, setPlainText] = useState<string>("");

  useEffect(() => {
    if (!ipfs) return;
    const readIPFSPayload = async () => {
      const htmlBytes = await getDecodedContent(
        ipfs.html.cid,
        ipfs.html.key,
        ipfs.html.head,
        ipfs.version
      );
      const plainBytes = await getDecodedContent(
        ipfs.plaintText.cid,
        ipfs.plaintText.key,
        ipfs.plaintText.head,
        ipfs.version
      );
      let html = Buffer.from(htmlBytes).toString("utf8");
      let plainText = Buffer.from(plainBytes).toString("utf8");

      let match,
        attachmentInfos: { [key: string]: IpfsAttachmentData } = {};
      while ((match = CID_REGEX.exec(html)) !== null) {
        const cid = match[1];
        const attachmentInfo = ipfs.attachments.find((p) => p.cid === cid);
        if (attachmentInfo) {
          attachmentInfos[cid] = attachmentInfo;
        }
      }

      const attachmentList = Object.values(attachmentInfos);
      const cidContents = await Promise.all(
        attachmentList.map((p) =>
          getDecodedContent(p.cid, p.key, p.head, ipfs.version)
        )
      );

      attachmentList.forEach((p, i) => {
        const attachmentSrc =
          "data:" +
          p.contentType +
          ";base64," +
          arrayBufferToBase64(cidContents[i]);
        let id = "cid:" + p.cid;
        html = html.split(id).join(attachmentSrc);
      });

      setHtml(html);
      setPlainText(plainText);
    };

    readIPFSPayload();
  }, [ipfs]);

  return {
    html,
    plainText,
  };
};
