import { useEffect, useState, useRef } from "react";

import {
  VerificationService,
  VerificationData,
  VerificationError,
  StatusData,
} from "@vereign/light-utils";
import getConfig from "../config";

export const STATE_LOADING_STATUS_DATA = 0;
export const STATE_VERIFYING_SENDER = 1;
export const STATE_VERIFYING_RECEIVERS = 2;
export const STATE_VERIFYING_ATTACHMENTS = 3;
export const STATE_VERIFICATION_COMPLETED = 4;
export const STATE_ERROR = 5;
export const STATE_UNVERIFIED = 6;
export const STATE_STATUS_DATA_PENDING = 7;
export const STATE_TAIL_PENDING = 8;
export const STATE_PENDING = 9;

export const UNVERIFIED_STATES = new Set([
  STATE_ERROR,
  STATE_UNVERIFIED,
  STATE_STATUS_DATA_PENDING,
  STATE_TAIL_PENDING,
  STATE_PENDING,
]);

export default (
  senderStatusData?: StatusData,
  receiversStatusesData?: Array<StatusData>
) => {
  const [senderVerificationDetails, setSenderVerificationDetails] =
    useState<VerificationData>();
  const [receiversVerificationDetails, setReceiversVerificationDetails] =
    useState<{
      [key: string]: VerificationData;
    }>();
  const [error, setError] = useState<Error>();

  const verificationService = useRef(
    new VerificationService(
      getConfig().CDN.BASE_URL,
      getConfig().CDN.STATUSES_BUCKET,
      getConfig().AETERNITY.NODES_URLS,
      getConfig().AETERNITY.COMPILERS_URLS,
      getConfig().AETERNITY.CONTRACT_BYTECODE
    )
  );

  /**
   * Process sender verification
   */
  useEffect(() => {
    senderStatusData &&
      senderStatusData.transactionHash &&
      verificationService.current
        .verifyStatusData(senderStatusData)
        .then((verificationResult) => {
          setSenderVerificationDetails(verificationResult);
          if (verificationResult.verificationError) {
            setError(
              new VerificationError(
                `Sender verification problem. ${verificationResult.verificationError}`
              )
            );
          }
        })
        .catch(setError);
  }, [senderStatusData]);

  /**
   * Process receivers verification
   */
  useEffect(() => {
    senderVerificationDetails &&
      !senderVerificationDetails?.verificationError &&
      receiversStatusesData &&
      Promise.all(
        receiversStatusesData
          .filter((statusData) => !!statusData.transactionHash)
          .map((statusData) =>
            verificationService.current.verifyStatusData(statusData)
          )
      )
        .then((verificationResults) => {
          const details = verificationResults.reduce(
            (acc: { [key: string]: VerificationData }, result) =>
              result.statusData
                ? Object.assign(acc, {
                    [result.statusData?.transactionHash]: result,
                  })
                : acc,
            {}
          );

          setReceiversVerificationDetails(details);

          const failed = verificationResults.find(
            (result) => !!result.verificationError
          );

          failed?.verificationError &&
            setError(
              new VerificationError(
                `Receivers verification problem. ${failed.verificationError}`
              )
            );
        })
        .catch(setError);
  }, [senderVerificationDetails, receiversStatusesData]);

  return {
    senderVerificationDetails,
    receiversVerificationDetails,
    error,
  };
};
