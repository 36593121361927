import { formatDistance } from "date-fns";

import "./BlockchainInfo.scss";

import { VerificationData, StatusData } from "@vereign/light-utils";

import getConfig from "../../../../config";
import Card from "../../../../components/Card";
import classnames from "classnames";

interface Props {
  statusData?: StatusData;
  verificationDetails?: VerificationData;
  statusId?: string;
}

const THRESHOLD_CONFIRMATIONS = 2;

const BlockchainInfo = ({
  statusData,
  verificationDetails,
  statusId,
}: Props) => {
  const confirmationsAmount =
    verificationDetails?.blockData?.confirmationsAmount || -1;

  return (
    <Card color="white" className="blockchain-info__container--left">
      <h1 className="blockchain-info__title">
        Blockchain entry for this record
      </h1>
      <ul className="blockchain-info__list">
        <li className="blockchain-info__list-item">
          <span className="blockchain-info__list-item-label">
            Block confirmations
          </span>
          <span className="blockchain-info__list-item-value">
            {confirmationsAmount >= 0 ? confirmationsAmount : "Unknown"}
          </span>
        </li>
        <li className="blockchain-info__list-item">
          <span className="blockchain-info__list-item-label">Block age</span>
          <span className="blockchain-info__list-item-value">
            {verificationDetails?.blockData?.timestamp
              ? formatDistance(
                  verificationDetails.blockData.timestamp,
                  new Date()
                )
              : "Unknown"}
          </span>
        </li>
        <li className="blockchain-info__list-item">
          <span className="blockchain-info__list-item-label">
            Anchoring block
          </span>
          {verificationDetails?.blockData?.blockHeight ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={classnames("blockchain-info__list-item-value-link", {
                "blockchain-info__list-item-value-link--disabled":
                  confirmationsAmount <= THRESHOLD_CONFIRMATIONS,
              })}
              href={getConfig().AETERNITY.BLOCK_EXPLORER_TEMPLATE.replace(
                "{{block}}",
                verificationDetails?.blockData?.blockHeight.toString()
              )}
            >
              {verificationDetails?.blockData?.blockHeight}
            </a>
          ) : (
            <span className="blockchain-info__list-item-value">Unknown</span>
          )}
        </li>
        <li className="blockchain-info__list-item">
          <span className="blockchain-info__list-item-label">
            Blockchain record
          </span>
          {statusData?.transactionHash ? (
            <a
              target="_blank"
              className={classnames("blockchain-info__list-item-value-link", {
                "blockchain-info__list-item-value-link--disabled":
                  confirmationsAmount <= THRESHOLD_CONFIRMATIONS,
              })}
              rel="noopener noreferrer"
              href={getConfig().AETERNITY.TX_EXPLORER_TEMPLATE.replace(
                "{{tx}}",
                statusData?.transactionHash
              )}
            >
              {statusData?.transactionHash}
            </a>
          ) : (
            <span className="blockchain-info__list-item-value">Unknown</span>
          )}
        </li>
        <li className="blockchain-info__list-item">
          <span className="blockchain-info__list-item-label">
            Status in Block
          </span>
          <span className="blockchain-info__list-item-value">
            {typeof verificationDetails?.batchVerificationDetails
              ?.statusPosition === "number" &&
            typeof verificationDetails?.batchVerificationDetails?.batchSize ===
              "number"
              ? `Status #${verificationDetails?.batchVerificationDetails.statusPosition} of ${verificationDetails?.batchVerificationDetails.batchSize}`
              : "Unknown"}
          </span>
        </li>
        <li className="blockchain-info__list-item">
          <span className="blockchain-info__list-item-label">Seal Id</span>
          <span className="blockchain-info__list-item-value">
            {statusId || "Unknown"}
          </span>
        </li>
      </ul>
    </Card>
  );
};

export default BlockchainInfo;
