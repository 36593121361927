import { useEffect } from "react";

import {
  STATE_ERROR,
  STATE_STATUS_DATA_PENDING,
  STATE_TAIL_PENDING,
  STATE_UNVERIFIED,
  STATE_VERIFYING_SENDER,
} from "../../../hooks/useVerificationFlow";
import "./EmailVerification.scss";
import ProgressCard from "./ProgressCard";
import { MessageData } from "@vereign/light-utils";
import IPFSCard from "./IPFSCard";
import AttachmentsCard from "./AttachmentsCard";

import Icon from "../../../components/Icon";
import exclamation from "../../../assets/images/exclamation.svg";
import hourglass from "../../../assets/images/hourglass.svg";
import lottie from 'lottie-web'
import wvAnim from '../../../assets/images/wva-animation';


interface Props {
  className: string;
  verificationState: number;
  sealTime: number;
  qrCodeData?: MessageData;
  numReceiversVerified: number;
}

const EmailVerification = ({
  className,
  verificationState,
  sealTime,
  qrCodeData,
  numReceiversVerified,
}: Props) => {

  function loadAnimation() {
    return lottie.loadAnimation({
      container: document.getElementById('animationLoop') as HTMLElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      // path: "../../../assets/images/lottie.json", // does not render
      animationData: JSON.parse(wvAnim),
      initialSegment: [0, 67],
    })
    
  }

  function finishAnimation() {
    lottie.destroy()
    return lottie.loadAnimation({
      container: document.getElementById('animationEnd') as HTMLElement,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      // path: "../../../assets/images/lottie.json", // does not render
      animationData: JSON.parse(wvAnim),
    })
    
  }


  useEffect(() => {
    qrCodeData ? finishAnimation() : loadAnimation()
  }, [qrCodeData])


  let icon = (() => {
    switch (true) {
      case verificationState === STATE_ERROR ||
        verificationState === STATE_UNVERIFIED:
        return <Icon
          width={50}
          height={50}
          src={exclamation}
          alt="verification icon"
        />;
      case verificationState === STATE_STATUS_DATA_PENDING ||
        verificationState === STATE_TAIL_PENDING:
        return <Icon
          width={50}
          height={50}
          src={hourglass}
          alt="verification icon"
        />;
      case verificationState > STATE_VERIFYING_SENDER:
        return null
      default:
        return null;
    }
  })();

  return (
    <div className={className}>
      <div className="verification__icon__box" id='iconcont'>
        <div className="verification__icon">
          {icon}
          {
            qrCodeData ?
            verificationState === STATE_STATUS_DATA_PENDING ||
            verificationState === STATE_TAIL_PENDING ||
            verificationState === STATE_ERROR ||
            verificationState === STATE_UNVERIFIED ?
              <></>
              :
              <div id='animationEnd' style={{
                overflow: 'hidden'
              }}></div>
              :
              verificationState === STATE_STATUS_DATA_PENDING ||
              verificationState === STATE_TAIL_PENDING ||
              verificationState === STATE_ERROR ||
              verificationState === STATE_UNVERIFIED ?
                <></>
                :
                <div id='animationLoop' style={{
                  overflow: 'hidden'
                }}></div>
          }
          
        </div>
      </div>
      <ProgressCard
        verificationState={verificationState}
        qrCodeData={qrCodeData}
        sealTime={sealTime}
      />
      <IPFSCard qrCodeData={qrCodeData} />
      <AttachmentsCard qrCodeData={qrCodeData} />

      {/* {verificationState === STATE_VERIFICATION_COMPLETED &&
        numReceiversVerified > 0 && <VerifiedCard />} */}
    </div>
  );
};

export default EmailVerification;
