import { useEffect, useState } from "react";
import VerificationPage from "./VerificationPage";
import useVerificationFlow, {
  STATE_ERROR,
  STATE_LOADING_STATUS_DATA,
  STATE_STATUS_DATA_PENDING,
  STATE_TAIL_PENDING,
  STATE_UNVERIFIED,
  STATE_VERIFICATION_COMPLETED,
  STATE_VERIFYING_ATTACHMENTS,
  STATE_VERIFYING_RECEIVERS,
  STATE_VERIFYING_SENDER,
} from "../../hooks/useVerificationFlow";
import useQrCodeData, {
  TAIL_PENDING_ERROR_MESSAGE,
} from "../../hooks/useQrCodeData";
import useStatuses from "../../hooks/useStatuses";
import { SenderStatusObject, VerificationError } from "@vereign/light-utils";


const VerificationPageContainer = () => {
  const [error, setError] = useState<Error>();
  const [verificationState, setVerificationState] = useState<number>(
    STATE_LOADING_STATUS_DATA
  );

  const {
    qrCodeData,
    sealId,
    sealHash,
    error: qrCodeDataError,
  } = useQrCodeData();

  const {
    statusesData,
    senderStatusData,
    receiversStatusesData,
    error: readingStatusesError,
  } = useStatuses(sealId, qrCodeData?.senderPublicKeyUuid, sealHash);

  const {
    senderVerificationDetails,
    receiversVerificationDetails,
    error: verificationFlowError,
  } = useVerificationFlow(senderStatusData, receiversStatusesData);

  useEffect(
    () => readingStatusesError && setError(readingStatusesError),
    [readingStatusesError]
  );

  useEffect(() => {
    if (qrCodeDataError) {
      if (qrCodeDataError.message === TAIL_PENDING_ERROR_MESSAGE) {
        setVerificationState(STATE_TAIL_PENDING);
      } else {
        setError(qrCodeDataError);
      }
    }
  }, [qrCodeDataError]);

  useEffect(
    () => verificationFlowError && setError(verificationFlowError),
    [verificationFlowError]
  );

  useEffect(() => {
    if (error) {
      console.error(error.message);
      if (error instanceof VerificationError) {
        setVerificationState(STATE_UNVERIFIED);
      } else {
        setVerificationState(STATE_ERROR);
      }
    }
  }, [error]);

  /**
   * In case status data is still pending sealing into blockchain
   */
  useEffect(() => {
    statusesData &&
      !statusesData.length &&
      setVerificationState(STATE_STATUS_DATA_PENDING);
  }, [statusesData]);

  useEffect(() => {
    senderStatusData &&
      !senderStatusData.transactionHash &&
      setVerificationState(STATE_STATUS_DATA_PENDING);
  }, [senderStatusData]);

  /**
   * Email sent. Verify sender seal
   */
  useEffect(() => {
    senderStatusData &&
      senderStatusData.transactionHash &&
      verificationState === STATE_LOADING_STATUS_DATA &&
      setVerificationState(STATE_VERIFYING_SENDER);
  }, [senderStatusData, verificationState]);

  /**
   * Sender seal verified. Verify recipients.
   */
  useEffect(() => {
    senderVerificationDetails &&
      !senderVerificationDetails.verificationError &&
      verificationState === STATE_VERIFYING_SENDER &&
      setVerificationState(STATE_VERIFYING_RECEIVERS);
  }, [senderVerificationDetails, verificationState]);

  /**
   * Recipients verified. Start verifying attachments.
   */
  useEffect(() => {
    const verificationDetails =
      receiversVerificationDetails &&
      Object.values(receiversVerificationDetails);

    if (
      verificationState === STATE_VERIFYING_RECEIVERS &&
      receiversStatusesData &&
      verificationDetails &&
      verificationDetails.length === receiversStatusesData.length &&
      verificationDetails.every(({ verificationError }) => !verificationError)
    ) {
      setVerificationState(STATE_VERIFYING_ATTACHMENTS);
    }
  }, [receiversStatusesData, receiversVerificationDetails, verificationState]);

  /**
   * Check out attachments hashes integrity and complete verification in case all good.
   */
  useEffect(() => {
    if (
      qrCodeData &&
      senderStatusData &&
      verificationState === STATE_VERIFYING_ATTACHMENTS
    ) {
      if (!qrCodeData?.attachments) {
        setVerificationState(STATE_VERIFICATION_COMPLETED);
        return;
      }

      const signaturesInStatusData: { [key: string]: number } = {};
      let attachmentsVerified = true;

      const attachmentsSignatures = (
        senderStatusData.status as SenderStatusObject
      ).attachmentsSignatures;

      if (attachmentsSignatures) {
        Object.values(attachmentsSignatures).forEach(({ list }) => {
          list.forEach((attachmentSignature) => {
            signaturesInStatusData[attachmentSignature.value] = 1;
          });
        });

        attachmentsVerified = qrCodeData.attachments.every((attachment) => {
          return attachment.signature in signaturesInStatusData;
        });
      }

      if (attachmentsVerified) {
        setVerificationState(STATE_VERIFICATION_COMPLETED);
      } else {
        setError(new VerificationError("Error verifying attachments"));
      }
    }
  }, [qrCodeData, senderStatusData, verificationState]);

  return (
    <VerificationPage
      senderVerificationDetails={senderVerificationDetails}
      senderStatusData={senderStatusData}
      receiversVerificationDetails={receiversVerificationDetails}
      qrCodeData={qrCodeData}
      verificationState={verificationState}
      error={error}
      sealId={sealId}
    />
  );
};

export default VerificationPageContainer;
