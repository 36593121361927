import { useEffect, useMemo, useRef, useState } from "react";
import {
  CloudflareService,
  SenderStatusObject,
  StatusData,
  VerificationError,
  StatusesService,
  CryptoService,
  CommonUtils,
} from "@vereign/light-utils";
import getConfig from "../config";

const { SENDER_CLASS_NAME } = StatusesService;

export default (
  statusId?: string,
  senderPublicKeyUuid?: string,
  sealHash?: ArrayBuffer
) => {
  const [statusesData, setStatusesData] = useState<Array<StatusData>>();
  const [senderStatusData, setSenderStatusData] = useState<StatusData>();
  const [error, setError] = useState<Error>();
  const cdnService = useRef(
    new CloudflareService(
      getConfig().CDN.BASE_URL,
      getConfig().CDN.STATUSES_BUCKET
    )
  );

  useEffect(() => {
    if (statusId) {
      cdnService.current
        .readStatuses(statusId)
        .then(setStatusesData)
        .catch(setError);
    }
  }, [statusId]);

  /**
   * Find sender statuses
   */
  useEffect(() => {
    const findSenderStatus = async (
      statusesData: Array<StatusData>,
      sealHash: ArrayBuffer
    ) => {
      const matchedStatusesData: Array<StatusData> = [];

      const {
        data: { key: publicKey },
      } = await cdnService.current.fetchFile<{ key: string }>(
        `pubkey-${senderPublicKeyUuid}`,
        getConfig().CDN.KEYS_BUCKET
      );

      const publicKeyPEM = new TextDecoder().decode(
        CommonUtils.base64ToArrayBuffer(publicKey)
      );

      for (const statusData of statusesData) {
        if (statusData.statusClassName === SENDER_CLASS_NAME) {
          const matchedStatusObject = statusData.status as SenderStatusObject;

          const signatureTimeVerified = await CryptoService.verifyRSASignature(
            publicKeyPEM,
            new TextEncoder().encode(
              matchedStatusObject.signatureTime.toString(16)
            ),
            CommonUtils.base64ToArrayBuffer(
              matchedStatusObject.signatureTimeSignature
            )
          );

          if (!signatureTimeVerified) {
            setError(
              new VerificationError(
                "Could not verify sender status signature time"
              )
            );
            return;
          }

          const sealHashVerified = await CryptoService.verifyRSASignature(
            publicKeyPEM,
            sealHash,
            CommonUtils.base64ToArrayBuffer(
              matchedStatusObject.sealSignature as string
            )
          );

          if (!sealHashVerified) {
            setError(new VerificationError("Could not verify seal integrity"));
            return;
          }

          matchedStatusesData.push(statusData);
        }
      }

      if (matchedStatusesData.length === 0) {
        setError(new VerificationError("Sender not found"));
      } else if (matchedStatusesData.length > 1) {
        setError(
          new VerificationError(
            "More than one sender status present for provided status id"
          )
        );
      } else if (matchedStatusesData.length === 1) {
        setSenderStatusData(matchedStatusesData[0]);
      }
    };

    statusesData &&
      statusesData.length &&
      sealHash &&
      findSenderStatus(statusesData, sealHash).catch(setError);
  }, [statusesData, senderPublicKeyUuid, sealHash]);

  const receiversStatusesData = useMemo(() => {
    return (
      statusesData &&
      senderStatusData &&
      statusesData.filter(
        (statusData) =>
          statusData !== senderStatusData && statusData.transactionHash
      )
    );
  }, [statusesData, senderStatusData]);

  return {
    senderStatusData,
    receiversStatusesData,
    statusesData,
    error,
  };
};
