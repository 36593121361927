
import "./Footer.scss";
import getConfig from "../../config";
// Ignore TS errors until we have type definition for react-ui
// @ts-ignore
import { Tooltip } from "@vereign/react-ui";
import "@vereign/react-ui/src/components/tooltip/Tooltip.scss";

import chromeStoreIcon from "../../assets/images/seal_e-mail/g-mail.png";
import appSourceIcon from "../../assets/images/seal_e-mail/outlook.png";
import Icon from "../../components/Icon";
import TotalSealedCard from "../../routes/VerificationPage/EmailVerification/TotalSealedCard";

const Footer = () => {
  return (
    <>
      <TotalSealedCard />

      <footer className="footer">
        <section className="footer__disclaimer">
          <p className="footer__disclaimer__pb">
            No intermediaries, no central points of failure, just{" "}
            <strong>Email that does more.</strong>
          </p>
          <br />
          <p>
            Web3 digital twins for email offer proof of sending and content at a level that is better than registered paper mail. They document consent at a level equivalent to Advanced Electronic Signatures (AES) and provide revision secure archival and robust grounds for dispute resolution. Learn more about Web3 digital twins at <a href='https://www.vereign.com/products/web3twin/email' target='blank' rel='noreferrer' style={{textDecoration: 'none', color: 'teal'}}>vereign.com/products/web3twin/email</a>.
          </p>
          <div style={{marginTop: '20px'}}>
            Vereign SEAL fully respects your personal data -{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={getConfig().PRIVACY_POLICY_URL}
              className="footer__privacy-policy-link"
            >
              learn how
            </a>
            .
          </div>
          <div className="footer__creator">
            Made with&nbsp;
            <span role="img" aria-label="love">
              ❤️
            </span>
            &nbsp;by Vereign AG, Kolinplatz 10, 6300 Zug, Switzerland
          </div>
        </section>
        <section className="footer__extensions">
          <p className="footer__disclaimer__pb">For automatic verification of all messages and to start sealing your own email, please install the Add-Ins:</p>
          <div className="stores-card__stores">
            <Tooltip text="Download" className="stores-card__stores__tooltip">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getConfig().CHROME_STORE_EXTENSION_URL}
                className="stores-card__link"
              >
                <span className="stores-card__icon">
                  <Icon width={72} height={72} src={chromeStoreIcon} />
                </span>
                <span className="stores-card__store-name-chrome-store">
                  Gmail
                </span>
              </a>
            </Tooltip>
            <Tooltip text="Download" className="stores-card__stores__tooltip">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getConfig().APP_SOURCE_ADDON_URL}
                className="stores-card__link"
              >
                <span className="stores-card__icon">
                  <Icon width={72} height={72} src={appSourceIcon} />
                </span>
                <span className="stores-card__store-name-app-source">
                  Outlook
                </span>
              </a>
            </Tooltip>
          </div>
          <p className="footer__extensions__text">or send a copy of your email to <strong>seal@vereign.com</strong> for automatic processing into a Web3 digital twin.</p>
        </section>
        
      </footer>
    </>
  );
};

export default Footer;
