
import classnames from "classnames";

import "./Page.scss";

interface Props {
  children: any;
  className?: string;
}

const Page = ({ children, className }: Props) => {
  return <main className={classnames("page", className)}>{children}</main>;
};

export default Page;
