import "../styles/main.scss";
import VerificationPageContainer from "../routes/VerificationPage";
import Footer from "./Footer";
import GreetingsPage from "../routes/GreetingsPage";
import { getSealHead } from "../utils/stringUtils"
import "./App.scss";

function App() {
  const sealHead = getSealHead(window.location.href);

  return (
    <section className="app">
      {sealHead ? <VerificationPageContainer /> : <GreetingsPage />}
      <Footer />
    </section>
  );
}

export default App;
