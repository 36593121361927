
import "./GreetingsPage.scss";
import Page from "../../components/Page";
import exclamationIcon from "../../assets/images/exclamation.svg";
import CardWithIcon from "../../components/CardWithIcon";
import Icon from "../../components/Icon";


const GreetingsPage = () => {
  const icon = <Icon src={exclamationIcon} width={43} height={43} />;

  const card = (
    <div className="greetings-page__tab-content">
      <CardWithIcon
        className="greetings-page__card"
        color="white"
        icon={icon}
        iconBg="white"
      >
        <h1 className="greetings-page__title">Oh, hi there…</h1>
        <p className="greetings-page__message">
          The QR code you scanned or clicked is a placeholder we show before the
          actual QR code is generated. <br /> <br />
          When you finish composing your email and send it, the real QR code is
          generated as the transaction is logged on the blockchain.
          <br />
          Sorry, there&apos;s no prize!
        </p>
      </CardWithIcon>
    </div>
  );

  return (
    <Page>
      {card}
    </Page>
  );
};

export default GreetingsPage;
