import { useEffect, useState } from "react";
import classnames from "classnames";

import "./GeneratedAvatar.scss";
import {
  generateColorFromString,
  requestGravatar,
} from "../../utils/stringUtils";


interface Props {
  email?: string;
  personName?:string;
  smallFont?: boolean;
  className?: string;
  onClick?: (e: any) => void;
}

const GeneratedAvatar = ({ email, personName, smallFont, className, onClick }: Props) => {
  const [gravRes, setGravRes] = useState<number>();

  let generatedGradient = (str: string | undefined) => {
    let senderEmail = str || "undefined.email@example.com";
    const seedStrings = senderEmail.split("@");

    let colorOne = 'hsl(0, 100%, 100%)'
    let colorTwo = generateColorFromString(seedStrings[0]);

    let randomAngle = Math.floor(Math.random() * 360);

    if (str) {
      randomAngle = Math.floor(1/str.length * 360);
    }

    return `linear-gradient(${randomAngle}deg, ${colorOne}, ${colorTwo})`;
  };

  let gravatarUrl = requestGravatar(email);

  async function handleResponseFromGravatar(url: string | undefined) {
    
    await fetch(url as string)
    .then((res) => {
      setGravRes(res.status);
    })
    .catch((err) => {
      console.log('Error getting gravatar.');
    })

  }

  useEffect(() => {
    gravatarUrl && handleResponseFromGravatar(gravatarUrl);
  }, [gravatarUrl]);


  function generateInitials(email: string | undefined, name: string | undefined) {

    if (name) {
      let nameArr = name.split(' ')

      if (nameArr.length === 1) {
        return nameArr[0].substring(0,1).toUpperCase()
      }

      return nameArr[0].substring(0,1).toUpperCase()+nameArr[1].substring(0,1).toUpperCase();
    }

    if (!name && email) {
      return email.substring(0,1).toUpperCase()
    }

    return '...'
  }


  return (
    <>
      {gravRes !== 200 ? (
        <div
          style={{
            background: generatedGradient(email),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: smallFont ? '50%' : '24px',
            fontWeight: '600'
          }}
          onClick={onClick}
          className={classnames("gradient", className)}
        >
          <span>{generateInitials(email, personName)}</span>
        </div>
      ) : (
        <img
          src={gravatarUrl}
          width="100%"
          className={classnames("gradient", className)}
          alt='gravatar'
        />
      )}
    </>
  );
};

export default GeneratedAvatar;
