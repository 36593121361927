import {
  MessageData,
  SenderStatusObject,
  StatusData,
  VerificationData,
} from "@vereign/light-utils";

import "./VerificationPage.scss";
import Page from "../../components/Page";
import EmailVerification from "./EmailVerification";
import Details from "./Details";

interface Props {
  senderVerificationDetails?: VerificationData;
  senderStatusData?: StatusData;
  receiversVerificationDetails?: { [key: string]: VerificationData };
  verificationState: number;
  qrCodeData?: MessageData;
  error?: Error;
  sealId?: string;
}

const VerificationPage = ({
  senderStatusData,
  senderVerificationDetails,
  receiversVerificationDetails,
  qrCodeData,
  verificationState,
  error,
  sealId,
}: Props) => {
  const sentTime = (senderStatusData?.status as SenderStatusObject)
    ?.signatureTime;
  const sealTime = senderVerificationDetails?.txData?.time || 0;

  const numReceiversVerified = receiversVerificationDetails
    ? Object.keys(receiversVerificationDetails).length
    : 0;

  return (
    <Page>
      <EmailVerification
        className="verification-page__tab-content__verification"
        verificationState={verificationState}
        sealTime={sealTime}
        qrCodeData={qrCodeData}
        numReceiversVerified={numReceiversVerified}
      />
      <Details
        className="verification-page__tab-content__details"
        verificationState={verificationState}
        error={error}
        sealId={sealId}
        sealTime={sealTime}
        sentTime={sentTime}
        qrCodeData={qrCodeData}
        senderStatusData={senderStatusData}
        senderVerificationDetails={senderVerificationDetails}
        numReceiversVerified={numReceiversVerified}
      />
    </Page>
  );
};

export default VerificationPage;
