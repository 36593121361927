import { useEffect, useState } from "react";
import { unlockAESKey } from "../services/API";
import {
  CloudflareService,
  MessageData,
  CryptoService,
  QrCodeDataService,
  CommonUtils,
  VerificationError,
} from "@vereign/light-utils";
import { getSealHead, getSealId } from "../utils/stringUtils"
import getConfig from "../config";

interface BackblazeDataPartRaw {
  key_signature: string;
  qr_code_data: string;
  session_key: string;
}

export const TAIL_PENDING_ERROR_MESSAGE = "Tail pending to be uploaded";

export default () => {
  const [qrCodeData, setQrCodeData] = useState<MessageData>();
  const [sealId, setSealId] = useState<string>();
  const [sealHash, setSealHash] = useState<ArrayBuffer>();
  const [error, setError] = useState<Error>();

  /**
   * Reads QR payload
   */
  useEffect(() => {
    const readQRCodePayload = async (baseUrl: string) => {
      const locationUrl = new URL(window.location.href);

      const sealUrl = `${baseUrl}/${locationUrl.hash}`;      

      const sealId = await getSealId(sealUrl);
      const sealHead = getSealHead(sealUrl);
      

      if (!sealHead) {
        setError(
          new VerificationError("Cannot obtain head part of the seal from URL")
        );
        return;
      }

      const cdnService = new CloudflareService(
        getConfig().CDN.BASE_URL,
        getConfig().CDN.STATUSES_BUCKET
      );

      let qrCodeDataPart;
      try {
        qrCodeDataPart = QrCodeDataService.decodeKeyDataPair(sealHead);
      } catch (e) {
        setError(e as Error);
        return;
      }
      

      let backblazeDataPart;
      try {
        ({ data: backblazeDataPart } =
          await cdnService.fetchFile<BackblazeDataPartRaw>(
            `qrcode-${sealId}`,
            getConfig().CDN.TAILS_BUCKET
          ));
      } catch (e) {
        setError(new Error(TAIL_PENDING_ERROR_MESSAGE));
        return;
      }

      // ------ doesn't reach here with wrong base url
      

      try {
        const assembledData = QrCodeDataService.assembleQrCodeData(
          CommonUtils.base64ToArrayBuffer(qrCodeDataPart.data),
          CommonUtils.base64ToArrayBuffer(backblazeDataPart.qr_code_data)
        );

        const sealHash = await CryptoService.SHA256(assembledData);
        setSealHash(sealHash);

        const { key: aesEncryptedSessionKey, data: storageIv } =
          QrCodeDataService.decodeKeyDataPair(backblazeDataPart.session_key);

        const rsaEncryptedSessionKey = await CryptoService.decryptAESGCM(
          CommonUtils.base64ToArrayBuffer(aesEncryptedSessionKey),
          CommonUtils.base64ToArrayBuffer(qrCodeDataPart.key),
          CommonUtils.base64ToArrayBuffer(storageIv)
        );

        const encodedSessionKey = await unlockAESKey(
          backblazeDataPart.key_signature,
          rsaEncryptedSessionKey
        );

        const decodedSessionKey =
          QrCodeDataService.decodeKeyDataPair(encodedSessionKey);

        const decryptedEmailData = await CryptoService.decryptAESGCM(
          assembledData,
          CommonUtils.base64ToArrayBuffer(decodedSessionKey.key),
          CommonUtils.base64ToArrayBuffer(decodedSessionKey.data)
        );

        const decompressedEmailData =
          CommonUtils.decompressData(decryptedEmailData);
        const decodedEmailData = QrCodeDataService.decodeEmailData(
          decompressedEmailData
        );

        setQrCodeData(decodedEmailData);
        setSealId(sealId);

        
        return {
          decodedEmailData,
          sealId,
        }
      } catch (e) {
        setError(e as Error);
      }
    };


    const baseUrls = [getConfig().SEAL_BASE_URL, getConfig().SEAL_BASE_URL_2];


    const tryToGetData = async (baseUrls: string[]) => {
      
      for await (let i of baseUrls) {
        // console.log(`trying to get data from ${i}`);
        setError(undefined)
        let res = await readQRCodePayload(i)

        if (res?.decodedEmailData && res?.sealId) {
          break
        }
      }
    }

    tryToGetData(baseUrls);
    
  }, []);

  return {
    qrCodeData,
    sealId,
    error,
    sealHash,
  };
};
